<template>
  <div class="Input" :class="{'Input--active' : !!modelValue}">
    <input
      :type="currentType"
      :id="id"
      class="Input-tag"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
    >
    <label :for="id" class="Input-label">
      <slot>Label</slot>
    </label>
    <button
      v-if="isPasswordType"
      type="button"
      class="Input-notice"
      @click="togglePasswordType"
    >
      <img :src="getPasswordTypeIcon" class="Input-noticeIcon" />
    </button>
  </div>
</template>

<script>
  export default {
    name: 'Input',

    props: {
      id: String,
      modelValue: String,
      type: {
        type: String,
        default: 'text'
      }
    },

    data () {
      return {
        currentType: this.type
      }
    },

    computed: {
      isPasswordType () {
        return this.type === 'password';
      },
      getPasswordTypeIcon () {
        if (this.currentType === 'password') {
          return require('@/assets/type_text.png');
        }
        return require('@/assets/type_password.png');
      }
    },

    methods: {
      togglePasswordType () {
        this.currentType = this.currentType === 'password' ? 'text': 'password';
      }
    }
  }
</script>

<style lang="scss">
  .Input {
    position: relative;
    padding-top: 2rem;

    &-tag {
      padding: 1rem 1.5rem;
      border: 0.1rem solid $color-Black;
      border-radius: 1.5rem;
      background-color: rgba($color-White, 0.5);
    }

    &-label {
      position: absolute;
      top: 3rem;
      left: 1.5rem;
      font-size: 1.4rem;
      transition: top ease .4s;
    }

    &-notice {
      position: absolute;
      right: 1rem;
      top: 3.1rem;
      width: 1.5rem;
      height: 1.5rem;
      border: none;
      padding: 0;
      font-size: 0;
      background: transparent;
      cursor: pointer;

      &Icon {
        width: 1.5rem;
      }
    }

    &-tag:hover + &-label,
    &-tag:focus + &-label,
    &-tag:active + &-label,
    &--active &-label {
      top: 0;
    }
  }
</style>
