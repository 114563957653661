<template>
  <div class="Login">
    <form action="#" class="Login-form" @submit.prevent="handleSubmit">
      <img
        src="@/assets/lock.png"
        alt="Lock"
        class="Login-picto"
      >
      <app-input
        id="code"
        class="Login-input"
        v-model="code"
      >
        Code
      </app-input>
      <app-input
        id="secret"
        class="Login-input"
        type="password"
        v-model="secret"
      >
        Secret
      </app-input>
      <p v-if="error" class="Login-error">
        {{ error }}
      </p>
      <button type="submit" class="Login-submit Button">
        Accéder
      </button>
    </form>
  </div>
</template>

<script>
  import AppInput from '@/components/Input.vue';
  import GiftService from '@/services/gift.service';

  export default {
    name: 'Login',

    data () {
      return {
        code: '',
        secret: '',
        error: null
      }
    },

    components: {
      AppInput
    },

    methods: {
      async handleSubmit () {
        this.error = null;

        try {
          if (await GiftService.isValidGift(this.code, this.secret)) {
            return this.$router.push({ name: 'Gift', params: { code: this.code.toLowerCase() } })
          }
        } catch (e) {
          this.error = e.message;
        }
      }
    }
  }
</script>

<style lang="scss">
  .Login {
    @include Page();
    background: $color-Primary;

    &-form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
    }

    &-picto {
      max-width: 6rem;
    }

    &-input {
      margin: 1rem auto 0;
    }

    &-submit {
      margin-top: 1.5rem;
    }

    &-error {
      margin: 1rem 0 0;
      color: $color-Error;
      font-size: 1rem;
    }
  }

  .Button {
    padding: 1rem 1.5rem;
    border: 0.1rem solid $color-Secondary;
    border-radius: 1.5rem;
    color: $color-White;
    background: $color-Secondary;
    cursor: pointer;
    transition: all ease .4s;

    &:hover {
      color: $color-Secondary;
      background-color: $color-White;
    }
  }
</style>
